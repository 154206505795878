import { Image } from "./image";
//import React from "react";
import React, { useState } from "react";
import Modal from "./modal";

export const Gallery = (props) => {

  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = (largeImage) => {
    setSelectedImage(largeImage);
  };
  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Portafolio</h2>
          <p>
            Estos son algunos proyectos.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                      onClick={() => handleImageClick(d.largeImage)}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
      <Modal show={selectedImage !== null} onClose={closeModal} image={selectedImage} />
    </div>
  );
};
