// Modal.js
import React, { useEffect } from "react";
import "./modal.css"; // Añade estilos para el modal

const Modal = ({ show, onClose, image }) => {

  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <img src={image} alt="Large view" className="modal-image" />
      </div>
    </div>
  );
};

export default Modal;
